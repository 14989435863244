<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-title class="py-0 px-3 mt-5 mb-0">
          <v-toolbar flat dense class="mb-5">
            <v-row dense justify="start">
              
              <v-col class="pa-0 mr-2 d-none">
                <v-menu
                  v-model="expiredDateMenu"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expiredDate"
                      append-icon="mdi-calendar-month"
                      label="Expired Date"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="expiredDate"
                    range
                    v-on:change="applyFilter"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pa-0 mr-2 d-none">
                <v-menu
                  v-model="manufactureDateMenu"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="manufacturingDate"
                      append-icon="mdi-calendar-month"
                      label="Manufactured Date"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="manufacturingDate"
                    range
                    v-on:change="applyFilter"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pa-0 mr-2">
                <v-select
                  v-model="asalGudang"
                  label="Gudang Asal"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  hide-details
                  :items="dataGudang"
                  v-on:change="getData"
                ></v-select>
              </v-col>
              <v-col class="pa-0 mr-2">
                <v-select
                  v-model="detailTransaksi"
                  label="Detail Transaksi"
                  dense
                  outlined
                  hide-details
                  :items="listDetail"
                  item-value="id"
                  item-text="name"
                  v-on:change="getData"
                ></v-select>
              </v-col>
              <v-col class="pa-0 mr-2">
                <v-select
                  v-model="tipeTransaksi"
                  label="Tipe Transaksi"
                  dense
                  outlined
                  hide-details
                  :items="listTipe"
                  item-value="id"
                  item-text="name"
                  v-on:change="getData"
                ></v-select>
              </v-col>
              <v-col class="pa-0 mr-2">
                    <v-select
                      v-model="expiredDates"
                      label="Expired Date"
                      dense
                      outlined
                      hide-details
                      :items="listExpiredDate"
                      item-value="expired_date"
                      v-on:change="applyFilter"
                    >
                      <template slot="selection" slot-scope="data">
                        <span v-if="data.item.expired_date == ''">all</span>
                        <span v-else>{{
                          data.item.expired_date.substring(0, 10)
                        }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <span v-if="data.item.expired_date == ''">all</span>
                        <span v-else>{{
                          data.item.expired_date.substring(0, 10)
                        }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="pa-0 mr-2">
                    <v-select
                      v-model="manufacturedDates"
                      label="Manufactured Date"
                      dense
                      outlined
                      hide-details
                      :items="listManufacturedDate"
                      item-value="manufacturing_date"
                      v-on:change="applyFilter"
                    >
                      <template slot="selection" slot-scope="data">
                        <span v-if="data.item.manufacturing_date == ''"
                          >all</span
                        >
                        <span v-else>{{
                          data.item.manufacturing_date.substring(0, 10)
                        }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <span v-if="data.item.manufacturing_date == ''"
                          >all</span
                        >
                        <span v-else>{{
                          data.item.manufacturing_date.substring(0, 10)
                        }}</span>
                      </template>
                    </v-select>
                  </v-col>
              <v-col class="pa-0">
                <download-csv :data="dataJsonToCsv" name="Laporan Scanner.csv">
                  <v-btn
                    color="#61B15A"
                    dark
                    class="mb-2 text-subtitle-1"
                    style="text-transform: unset !important"
                  >
                    <v-icon left>mdi-file-download</v-icon>
                    Unduh Data
                  </v-btn>
                </download-csv>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataScanner"
            :loading="loading"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            hide-default-footer
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-5">
                <v-text-field
                  outlined
                  dense
                  v-model="search"
                  append-icon="mdi-magnify"
                  autocomple="off"
                  placeholder="Search..."
                ></v-text-field>
              </v-toolbar>
              <div class="d-flex mb-5 px-4 justify-space-between align-center">
                <v-row>
                  <v-col cols="2">
                    <v-select
                      v-model="itemsPerPage"
                      :items="showPerPage"
                      outlined
                      dense
                      label="items per page"
                      v-on:change="changeItemPerPage"
                    >
                    </v-select>
                  </v-col>
                  <v-col align="right">
                    <span class="mr-15 text-caption">Page: {{ page }}</span>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="primary"
                      class="mx-3"
                      @click="prevPage"
                    >
                      <v-icon dark> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="primary"
                      class="mx-3"
                      @click="nextPage"
                    >
                      <v-icon dark> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-slot:item.barcode="{ item }">
              <span v-if="item.product_state == 'MASTER_CARTON'">{{item.mc_code}}</span>
              <span v-if="item.product_state == 'INNER_CARTON'">{{item.ic_code}}</span>
              <span v-if="item.product_state == 'PRODUCT_BTL'">{{item.qr_code}}</span>
            </template>

            <template v-slot:item.manufacturing_date="{ item }">
              {{ item.manufacturing_date | moment("YYYY/MM/DD") }}
            </template>

            <template v-slot:item.expired_date="{ item }">
              {{ item.expired_date | moment("YYYY/MM/DD") }}
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | moment("YYYY/MM/DD") }}
            </template>

            <template v-slot:item.created_time="{ item }">
              {{ item.created_at | moment("HH:MM") }}
            </template>
          </v-data-table>
          <div class="d-flex mt-5 px-4 justify-space-between align-center">
            <v-row>
              <v-col cols="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="showPerPage"
                  outlined
                  dense
                  label="items per page"
                  v-on:change="changeItemPerPage"
                >
                </v-select>
              </v-col>
              <v-col align="right">
                <span class="mr-15 text-caption">Page: {{ page }}</span>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="prevPage"
                >
                  <v-icon dark> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="nextPage"
                >
                  <v-icon dark> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    search: "",
    loading: false,
    manufactureDateMenu: false,
    manufacturingDate: [],
    expiredDateMenu: false,
    expiredDate: [],
    manufacturedDates: "",
    listManufacturedDate: [],
    expiredDates: "",
    listExpiredDate: [],
    asalGudang: "",
    detailTransaksi: "",
    tipeTransaksi: "",
    listTipe: [
      { id: "", name: "semua tipe" },
      { id: "IN", name: "Inbound" },
      { id: "OUT", name: "Outbound" },
    ],
    listDetail: [
      { id: "", name: "semua transaksi" },
      { id: "HASIL_PRODUKSI", name: "Hasil Produksi" },
      { id: "ANTAR_GUDANG", name: "Antar Gudang" },
      { id: "RETUR", name: "Retur" },
      { id: "SALES", name: "Sales" },
      { id: "SAMPLE", name: "Sampel" },
      { id: "DONASI", name: "Donasi" },
      { id: "EVENT", name: "Event" },
    ],
    headers: [
      {
        text: "Tipe Transaksi",
        class: "text-subtitle-2 font-weight-bold black--text",
        align: "start",
        value: "trx_type",
      },
      {
        text: "Detail Transaksi",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "Barcode",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "barcode",
      },
      {
        text: "MC Code",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "mc_code",
        align: "d-none"
      },
      {
        text: "IC Code",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "ic_code",
        align: "d-none"
      },
      {
        text: "QR Code",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "qr_code",
        align: "d-none"
      },
      {
        text: "Material",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "material_code",
      },
      {
        text: "Batch",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "batch_no",
      },
      {
        text: "Ref Number",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "ref_no",
      },
      {
        text: "Manufacturing Date",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "manufacturing_date",
      },
      {
        text: "Expired Date",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "expired_date",
      },
      {
        text: "Source Warehouse",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "gudang_asal_name",
      },
      {
        text: "Destination Warehouse",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "gudang_penerima_name",
      },
      {
        text: "Customer",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "customer_name",
      },
      {
        text: "Username",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "scanner_name",
      },
      {
        text: "Surat jalan",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "nomer_seri",
      },
      {
        text: "Created Date",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "created_at",
      },
      {
        text: "Time",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "created_time",
      },
    ],
    dataScanner: [],
    dataGudang: [],
    expired_from: "",
    expired_to: "",
    manufacture_from: "",
    manufacture_to: "",
    dataJsonToCsv: [],
    page: 1,
    itemsPerPage: 10,
    showPerPage: [5, 10, 20, 50, 100, 250, 500],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getGudang() {
      const defaultSelect = { id: "", name: "semua gudang" };
      this.dataGudang.push(defaultSelect);
      this.user.listGudang.forEach((v) => {
        axios.get("v1/user/gudang?id=" + v).then((response) => {
          if (response.data.data.is_activated === 1) {
            this.dataGudang.push(response.data.data);
          }
          if (this.user.listGudang[0] === response.data.data.id) {
            this.asalGudang = this.user.listGudang[0];
            this.applyFilter();
          }
        });
      });
    },
    applyFilter() {
      this.loading = true;
      this.expiredDateMenu = false;
      this.manufactureDateMenu = false;
      // if (this.expiredDate.length !== 0) {
      //   if (this.expiredDate[0] > this.expiredDate[1]) {
      //     this.expired_from = this.expiredDate[1] + "T00:00:00.000Z";
      //     this.expired_to = this.expiredDate[0] + "T23:59:59.000Z";
      //   } else {
      //     this.expired_from = this.expiredDate[0] + "T00:00:00.000Z";
      //     this.expired_to = this.expiredDate[1] + "T23:59:59.000Z";
      //   }
      // }
      // if (this.manufacturingDate.length !== 0) {
      //   if (this.manufacturingDate[0] > this.manufacturingDate[1]) {
      //     this.manufacture_from = this.manufacturingDate[1] + "T00:00:00.000Z";
      //     this.manufacture_to = this.manufacturingDate[0] + "T23:59:59.000Z";
      //   } else {
      //     this.manufacture_from = this.manufacturingDate[0] + "T00:00:00.000Z";
      //     this.manufacture_to = this.manufacturingDate[1] + "T23:59:59.000Z";
      //   }
      // }
      if (this.expiredDates !== "") {
        this.expired_from = this.expiredDates;
        this.expired_to = this.expiredDates.substring(0, 10) + "T23:59:59.000Z";
      }
      if (this.manufacturedDates !== "") {
        this.manufacture_from = this.manufacturedDates;
        this.manufacture_to =
          this.manufacturedDates.substring(0, 10) + "T23:59:59.000Z";
      }
      axios
        .get(
          "v1/admin/laporan/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            "&gudang_asal_id=" +
            this.asalGudang +
            "&detail=" +
            this.detailTransaksi +
            "&tipe=" +
            this.tipeTransaksi +
            "&expired_from=" +
            this.expired_from +
            "&expired_to=" +
            this.expired_to +
            "&manufacture_from=" +
            this.manufacture_from +
            "&manufacture_to=" +
            this.manufacture_to
        )
        .then((response) => {
          this.loading = false;
          this.dataScanner = response.data.data;
          this.unduhData();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.applyFilter();
            }
            console.clear();
          }
        });
    },
    changeItemPerPage() {
      this.applyFilter();
    },
    prevPage() {
      if (this.page === 1) {
        return;
      } else {
        this.page = this.page - 1;
        this.applyFilter();
      }
    },
    nextPage() {
      if (this.dataBarcode === null) {
        return;
      } else {
        this.page = this.page + 1;
        this.applyFilter();
      }
    },
    next(page) {
      this.page = page;
      this.applyFilter();
    },
    unduhData() {
      axios
        .get(
          "v1/admin/laporan/list?limit=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            "&gudang_asal_id=" +
            this.asalGudang +
            "&detail=" +
            this.detailTransaksi +
            "&tipe=" +
            this.tipeTransaksi +
            "&expired_from=" +
            this.expired_from +
            "&expired_to=" +
            this.expired_to +
            "&manufacture_from=" +
            this.manufacture_from +
            "&manufacture_to=" +
            this.manufacture_to +
            "&download=1"
        )
        .then((response) => {
          var parseData = this.$papa.parse(response.data, { header: true });
          this.dataJsonToCsv = parseData.data;
        });
    },
    getDate() {
      this.getExpiredDate();
      this.getManufacturedDate();
    },
    getData() {
      this.getDate();
      this.applyFilter();
    },
    async getExpiredDate() {
      const defaultSelect = { expired_date: "" };
      axios
        .get(
          "v1/admin/laporan/getListDate?gudang_asal_id=" +
            this.asalGudang +
            "&detail=" +
            this.detailTransaksi +
            "&tipe=" +
            this.tipeTransaksi +
            "&tipedate=EXPDATE" 
        )
        .then((response) => {
          this.listExpiredDate = response.data.data;
          this.listExpiredDate.push(defaultSelect);
        });
    },
    async getManufacturedDate() {
      const defaultSelect = { manufacturing_date: "" };
      axios
        .get(
          "v1/admin/laporan/getListDate?gudang_asal_id=" +
            this.asalGudang +
            "&detail=" +
            this.detailTransaksi +
            "&tipe=" +
            this.tipeTransaksi +
            "&tipedate=MANUFDATE" 
        )
        .then((response) => {
          this.listManufacturedDate = response.data.data;
          this.listManufacturedDate.push(defaultSelect);
        });
    },
  },

  created() {
    this.getGudang();
    this.getDate();
  },
};
</script>
